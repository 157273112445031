export const environment = {
  production: true,
  appVersion: 'v1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: 'https://api.superaides.apptechasia.app/app/',
  mapApiKey: 'AIzaSyB166EUus9MT9T8FkZTliW1R9oiYYzNKZU',
  heartlandKey:'pkapi_cert_DxPyDhu8QKeymR7gXm',
  orgDetails: {
    orgId: 1,
    superAideLabel: 'SuperAide',
    orgName: 'SuperAides',
    publicWebsiteUrl: 'https://superaides.com',
    logo: './assets/media/logos/superaid_logo.png',
    favIcon: './assets/media/logos/superaid_logo.png',
    termsNServiceUrl: 'https://superaides.com/termsnservice.html',
    contactUrl: 'https://superaides.com/termsnservice.html',
    privacyUrl: 'https://superaides.com/termsnservice.html'
  }
};
