import { Inject, Injectable } from "@angular/core";
import { AppHttpClient } from "./http-call/http-call.service";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    public configs: any;
    private API_URL = `${environment.apiUrl}v1/config`;

    constructor(private http: AppHttpClient) {
    }


    fetchConfigs() {
        this.http.get(this.API_URL).subscribe(resp => {
            this.configs = resp;
        })
    }

}