import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { AppHttpClient } from 'src/app/core/services/http-call/http-call.service';
import { Role } from 'src/app/core/app.constants';

const API_USERS_URL = `${environment.apiUrl}v1/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: AppHttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { email, password });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<any> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }


  // CREATE =>  POST: add a new user to the server
  validateUser(user: UserModel): Observable<any> {
    return this.http.post<UserModel>(API_USERS_URL + "/validate", user);
  }

  // CREATE =>  POST: add a new user to the server
  sendOtp(mobileNumber: string): Observable<any> {
    return this.http.post<UserModel>(API_USERS_URL + "/" + mobileNumber, null);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgotPassword`, {
      email,
    });
  }

  updateProfile(profileDetails: UserModel) {
    return this.http.put<boolean>(`${API_USERS_URL}`, profileDetails);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/changePassword`, {
      oldPassword,
      newPassword
    });

  }


  resetPassword(token: string, password: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/resetPassword`, {
      token,
      password
    });

  }


  getUserByToken(token): Observable<UserModel> {
    let ut: UserModel = new UserModel();
    // ut.setUser({
    //   email: 'tes',
    //   firstName: 'Pathik',
    //   lastName: 'Patel',
    //   id: 1,
    //   mobile: '',
    //   password: '',
    //   expiresIn: new Date('2022-01-01'),
    //   pic: '',
    //   refreshToken: '',
    //   role: Role.SUPER_ADMIN,
    //   token: 'ut',
    //   username: 'ut',

    // });
    // return of(ut);
    return this.http.get<UserModel>(`${API_USERS_URL}/profile`);
  }
}
